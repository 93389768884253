const PAGE_DICTIONARY: Record<string, string[]> = {
    overview: ['HOME', 'ACADEMY_HOME', 'EXPLORER_HOME'],
    assessment: ['ACADEMY', 'METRICS'],
    students: ['STUDENTS'],
    sessions: ['EXPLORER'],
    library: ['LIBRARY'],
    contact: ['CONTACT'],
    notification: ['NOTIFICATIONS'],
    settings: ['SETTINGS'],
    legal: ['LEGAL'],
    other: ['404', 'OTHERS'],
    login: ['LOGIN'],
    payments: ['PAYMENTS'],
}

const PAGE_TYPE: Record<string, string> = {}

Object.keys(PAGE_DICTIONARY).forEach((key) => {
    PAGE_DICTIONARY[key].forEach((page) => {
        PAGE_TYPE[page] = key
    })
})

export { PAGE_TYPE }

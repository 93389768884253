import { Injectable, OnDestroy } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import { Subscription, filter, map, switchMap } from 'rxjs'
import { PAGE_TYPE } from './analytics.data'
import { AuthService } from '@app/core/services/auth/auth.service'
import { AppTranslateService } from '@core/services/app-translate/app-translate.service'

interface IDefaultTrackingInterface {
    originalLocation: string | null
    pageCountry: string | null
    pageLanguage: string | null
    visitorId: string | null
}

type IPageEventTypes = 'page' | 'generic'

declare let gtag: Function
@Injectable()
export class AnalyticsService implements OnDestroy {
    private pageType: string
    private authSub: Subscription
    private transSub: Subscription
    private _defaultTrackingData: any = {}
    constructor(private router: Router, private authService: AuthService, private appTranslateService: AppTranslateService) {}

    init() {
        this.authSub = this.authService.logged.subscribe((status) => {
            if (status) {
                this._defaultTrackingData.visitorId = this.getUserId()
            } else {
                delete this._defaultTrackingData.visitorId
            }
        })

        this.transSub = this.appTranslateService.locale$.subscribe((x) => {
            this._defaultTrackingData.pageLanguage = x || this.appTranslateService.getLocale()
        })
    }

    ngOnDestroy() {
        if (this.authSub) {
            this.authSub.unsubscribe()
        }

        if (this.transSub) {
            this.transSub.unsubscribe()
        }
    }

    get pageCountry() {
        return 'int'
    }

    private gtag(event: IPageEventTypes, url: string | null, pageType: string, extraParam?: Record<any, any>) {
        const w: any = window
        const l = 'dataLayer'
        w[l] = w[l] || []
        w[l].push({
            event,
            ...(url ? { pageName: this.sanitizeUrl(url) } : {}),
            pageType,
            ...this._defaultTrackingData,
            visitorLoginState: !!this._defaultTrackingData.visitorId,
            ...(extraParam ?? {}),
        })
    }

    public initialize(GA_TRACKING_ID: string) {
        this.init()
        this.onRouteChange()
        this._defaultTrackingData.originalLocation = this.sanitizeUrl(window.location.href)
        this._defaultTrackingData.pageCountry = this.pageCountry
        // dynamically add analytics scripts to document head
        try {
            ;(function (w: any, d, s, l, i) {
                w[l] = w[l] || []
                w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })
                const f: any = d.getElementsByTagName(s)[0]
                const j: any = d.createElement(s)
                const dl = l != 'dataLayer' ? '&l=' + l : ''
                j.async = true
                j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl
                f.parentNode.insertBefore(j, f)
            })(window, document, 'script', 'dataLayer', GA_TRACKING_ID)
        } catch (e) {
            console.error('Error adding Google Analytics', e)
        }
    }

    // track visited routes
    private onRouteChange() {
        this.router.events
            .pipe(
                filter((event): event is NavigationEnd => event instanceof NavigationEnd),
                switchMap((x) => {
                    let route = this.router.routerState.root
                    while (route.firstChild) {
                        route = route.firstChild
                    }
                    return route.data.pipe(map((data) => [data as any, x]))
                })
            )
            .subscribe(([data, route]) => {
                const pageType = data.title ?? 'OTHERS'
                this.pageType = pageType
                this.gtag('page', route.url, PAGE_TYPE[pageType])
            })
    }

    private getUserId(): string {
        return this.authService.getStoreData().userId as string
    }

    pageView(pageType: string, url: string, extraParam?: Record<any, any>) {
        this.gtag('page', url, PAGE_TYPE[pageType], extraParam)
    }

    // use gtag.js to send Google Analytics Events
    // istanbul ignore next
    event(action: string, eventParams?: Record<string, any>) {
        this.gtag('generic', null, this.pageType, {
            event_name: action,
            [action]: eventParams,
        })
    }

    private sanitizeUrl(url: string): string {
        // remove userId
        return url.replace(/(userId=).*?(&)/, '$2').replace('?&', '?')
    }
}

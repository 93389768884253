import { DOCUMENT } from '@angular/common'
import { Inject, Injectable } from '@angular/core'
import { Meta, MetaDefinition, Title } from '@angular/platform-browser'
import { ActivatedRoute, Data, NavigationEnd, Router } from '@angular/router'
import { AppTranslateService } from '@app/core/services/app-translate/app-translate.service'
import { LinkService } from '@app/core/services/link/link.service'
import { TranslateService } from '@ngx-translate/core'
import { from, Observable, of, zip } from 'rxjs'
import { filter, first, map, mergeMap, switchMap, toArray } from 'rxjs/operators'

const DEFAULT_IMG = 'https://d15ol4y5vpli8m.cloudfront.net/002cf0a6-ef1a-417b-a094-67f3373ee0c6/og-image.png'
export const TRANSLATE_BASE_PATH = 'META_TAGS.'

interface Tags {
    title: string
    description: string
    url: string
}

@Injectable({
    providedIn: 'root',
})
export class HeadTagsService {
    dataStream$: Observable<Data> = this.router.events.pipe(
        filter((x): x is NavigationEnd => x instanceof NavigationEnd),
        map(() => this.route),
        switchMap((r) => {
            const dataStreams: Observable<Data>[] = []

            let route: ActivatedRoute | null
            for (route = r.root; route !== null; route = route.firstChild) {
                dataStreams.push(route.data)
            }
            return from(dataStreams).pipe(
                mergeMap((x) => x.pipe(first())),
                toArray(),
                map((datas) => {
                    return datas.reduce(
                        (acc, value) => ({
                            ...acc,
                            ...(value?.meta ?? {}),
                        }),
                        {}
                    )
                })
            )
        })
    )

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private meta: Meta,
        private appTranslateService: AppTranslateService,
        private translate: TranslateService,
        @Inject(DOCUMENT) private document: Document,
        private link: LinkService,
        private title: Title
    ) {}

    defaultTitle$: Observable<string> = this.translate.get(TRANSLATE_BASE_PATH + 'DEFAULTS.TITLE')
    defaultDescription$ = this.translate.get(TRANSLATE_BASE_PATH + 'DEFAULTS.DESCRIPTION')

    init() {
        const tags$: Observable<Tags> = this.dataStream$.pipe(
            switchMap((data) => {
                const titlePath = TRANSLATE_BASE_PATH + data?.title
                const descriptionPath = TRANSLATE_BASE_PATH + data?.description
                return zip(this.getTextValue(titlePath, 'title'), this.getTextValue(descriptionPath, 'description')).pipe(
                    map(([title, description]) => ({ title, description, url: this.currentUrl() }))
                )
            })
        )
        tags$.subscribe((x) => {
            this.updateTags(x)
        })
    }

    // for testing purposes
    // istanbul ignore next
    currentUrl(): string {
        return this.document.location.href
    }

    private updateTags(tags: Tags): void {
        this.getOgMetas(tags).forEach((x) => {
            const selector = `property='${x.property}'`
            const tag = this.meta.getTag(selector)
            if (tag) {
                this.meta.updateTag(x, selector)
            } else {
                this.meta.addTag(x)
            }
        })
        this.getTextMetas(tags).forEach((x) => {
            const selector = `name='${x.name}'`
            const tag = this.meta.getTag(selector)
            if (tag) {
                this.meta.updateTag(x, selector)
            } else {
                this.meta.addTag(x)
            }
        })
        this.updateLanguageTags(tags)
        this.title.setTitle(tags.title)
    }

    private getTextValue(path: string, type: 'title' | 'description'): Observable<string> {
        return this.translate.get(path).pipe(
            switchMap((text) => {
                // text is the same as path if translation is missing
                if (text !== path) {
                    return of(text)
                }
                return type === 'title' ? this.defaultTitle$ : this.defaultDescription$
            })
        )
    }

    private getOgMetas(tags: Tags): (MetaDefinition & { property: string })[] {
        return [
            {
                property: 'og:type',
                content: 'website',
            },
            {
                property: 'og:url',
                content: tags.url,
            },
            {
                property: 'og:title',
                content: tags.title,
            },
            {
                property: 'og:description',
                content: tags.description,
            },
            {
                property: 'og:image',
                content: DEFAULT_IMG,
            },
            {
                property: 'twitter:card',
                content: 'summary_large_image',
            },
            {
                property: 'twitter:url',
                content: tags.url,
            },
            {
                property: 'twitter:title',
                content: tags.title,
            },
            {
                property: 'twitter:description',
                content: tags.description,
            },
            {
                property: 'twitter:image',
                content: DEFAULT_IMG,
            },
        ]
    }

    private getTextMetas(tags: Tags): (MetaDefinition & { name: string })[] {
        return [
            {
                name: 'title',
                content: tags.title,
            },
            { name: 'description', content: tags.description },
        ]
    }

    private updateLanguageTags(tags: Tags): void {
        this.appTranslateService.getLangs().forEach((lang) => {
            const url = this.appTranslateService.getLangUrl(tags.url, lang)
            this.link.updateTag(
                {
                    rel: 'alternate',
                    href: url,
                    hreflang: lang,
                },
                'hreflang'
            )
        })
        this.link.updateTag(
            {
                rel: 'alternate',
                href: tags.url,
                hreflang: 'x-default',
            },
            'hreflang'
        )
    }
}

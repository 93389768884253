import { Component } from '@angular/core'
import { RouterOutlet } from '@angular/router'
import { AppTranslateService } from '@core/services/app-translate/app-translate.service'
import { MetaService } from '@services/meta/meta.service'
import { from } from 'rxjs'
import { HeadTagsService } from './core/services/head-tags/head-tags.service'
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
})
export class AppComponent {
    isLandingPage: boolean = false

    constructor(private translateService: AppTranslateService, private tags: HeadTagsService) {
        this.translateService.init()
        this.tags.init()
    }

    // !! Note: Let's skip this since we already handling this as part of ShrinkViewportService
    // onActivate(outlet: RouterOutlet) {
    //     from(outlet.activatedRoute.data).subscribe((data) => {
    //         console.log(data.responsive)
    //         if (data.responsive === true) {
    //             this.metaService.setMobileViewport()
    //         } else {
    //             this.metaService.setDefaultViewport()
    //         }
    //     })
    // }
}
